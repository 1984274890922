import React, { useState, useEffect, useCallback } from 'react'

import chevron from '../components/assets/chevron-up.svg'
import sideMenuCStyles from './styles/sideMenuContent.module.scss'

import actions from '../redux/actions'
const { useDispatch, useSelector, setSelectedCollection, setSelectedSharableCollection } = actions

export default function SideMenuContent (props) {
  const { collection, section } = props

  const [inside, setInside] = useState(false)
  const [insideR, setInsideR] = useState(true)
  const [lastSectionVerified, setLastSectionVerified] = useState(false)

  const insideClick = () => setInside(!inside)
  const rotateIn = () => setInsideR(!insideR)

  const selectedCollection = useSelector((state) => state.collections.selected)
  const selectedSharableCollection = useSelector((state) => state.collections.selectedSharable)
  const dispatch = useDispatch()

  // set selected collection to fetch tokens
  const setSelectCollectionToFetch = () => {
    if (section === 'collections') {
      dispatch(setSelectedCollection(collection))
    } else {
      dispatch(setSelectedSharableCollection(collection))
    }
  }
  // Updates the side-menu when switching between views.
  // Close all side-menus, and only leave the one of the selected collection open
  const fixSideMenuOnChangesView = useCallback(() => {
    if (section === lastSectionVerified) return

    // Check if it is in the personal collections view.
    if (section === 'collections') {
      // Open the side-menu of the selected collection
      if (selectedCollection && selectedCollection._id === collection._id) {
        setInside(true)
        setInsideR(false)
      } else {
        // Close the side-menus of collections that are not selected
        setInside(false)
        setInsideR(true)
      }
    }
    // Check if it is in the sharable collections view.
    if (section !== 'collections') {
      // Check if it is in the personal collections view.
      if (selectedSharableCollection && selectedSharableCollection.publicId === collection.publicId) {
        // Open the side-menu of the selected collection
        setInside(true)
        setInsideR(false)
      } else {
        // Close the side-menus of collections that are not selected
        setInside(false)
        setInsideR(true)
      }
    }
    setLastSectionVerified(section)
  }, [section, collection, selectedCollection, selectedSharableCollection, lastSectionVerified])

  useEffect(() => {
    fixSideMenuOnChangesView(section)
  }, [section, fixSideMenuOnChangesView])

  return (
    <>
      {
        collection && (collection._id || collection.publicId) && (
          <div className={sideMenuCStyles.container}>
            <div className={sideMenuCStyles.insideSection}>
              <div
                className={sideMenuCStyles.insideSectionTab}
                onClick={() => {
                  insideClick()
                  rotateIn()
                }}
              >
                {' '}
                {collection.projectLabel && <h3>{collection.projectLabel}</h3>}
                {collection.collectionLabel && <h3>{collection.collectionLabel}</h3>}
                <img
                  className={
                    insideR
                      ? `${sideMenuCStyles.chevron}`
                      : `${sideMenuCStyles.chevronDown}`
                  }
                  src={chevron}
                  alt='chevron icon'
                />{' '}
              </div>
              {inside
                ? (
                  <div className={sideMenuCStyles.insideTabs} onClick={setSelectCollectionToFetch}>
                    <span className={sideMenuCStyles.active}>
                      Tokens {collection.tokens && <span className={sideMenuCStyles.quantity}>({collection.tokens.length})</span>}
                    </span>
                  </div>
                  )
                : null}
            </div>
          </div>)
      }
    </>
  )
}
