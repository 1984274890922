
import axios from 'axios'
import { setUser, getUser } from './auth'
const SERVER = process.env.REACT_APP_API_URL

export const createUser = async ({ email, password }) => {
  // try auth
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/users`,
      headers: {
        Accept: 'application/json'
      },
      data: {
        user: {
          email,
          password,
          ignoreEmailCheck: true
        }

      }
    }
    const result = await axios(options)
    const response = result.data // await data.json()
    setUser({
      _id: response.user._id,
      email: response.user.email,
      jwt: response.token,
      username: response.user.username || '',
      emailVerified: response.user.emailVerified,
      chatId: response.user.chatId,
      credit: response.user.credit
    })
    return true
  } catch (e) {
    console.warn('Error in user/createUser()', e.message)
    throw e
  }
}

// Reset user password
export const resetPassword = async email => {
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/users/resetpassword/${email}`,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const resp = await axios(options)
    console.log(resp.data)
    if (!resp.data.success) {
      throw new Error(resp.data.msg)
    }
    return resp.data
  } catch (e) {
    console.log('error in services/user/resetPassword', e)
    throw e
  }
}

export const updateUser = async user => {
  try {
    const token = getUser().jwt ? getUser().jwt : ''

    const options = {
      method: 'PUT',
      url: `${SERVER}/users/${user._id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        user
      }
    }
    const result = await axios(options)

    const response = result.data
    setUser({
      _id: response.user._id,
      email: response.user.email,
      jwt: token,
      username: response.user.username || '',
      emailVerified: response.user.emailVerified,
      chatId: response.user.chatId,
      credit: response.user.credit
    })
    return response
  } catch (err) {
    console.log('error in user/updateUser')
    throw err
  }
}

export const getUserTokens = async () => {
  const user = getUser()
  const token = user.jwt
  const userId = user._id

  // try to get all projects
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/user-tokens`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        userId
      }
    }
    const response = await axios(options)
    const resp = response.data
    console.log('resp', resp)
    return resp
  } catch (e) {
    return false
  }
}

export const getWalletAddresses = async () => {
  const user = getUser()
  const token = user.jwt
  const userId = user._id

  // try to get all projects
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/users/addr/${userId}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(options)
    const resp = response.data
    console.log('resp', resp)
    return resp
  } catch (e) {
    return false
  }
}

export const exportUserToken = async (tokenId, slpAddress) => {
  const user = getUser()
  const token = user.jwt

  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/export/user`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        tokenId,
        slpAddress
      }
    }
    const response = await axios(options)
    const resp = response.data
    // console.log('resp', resp)
    return resp.result
  } catch (e) {
    return false
  }
}

export const getUsers = async () => {
  const user = getUser()
  const token = user.jwt

  // try to get all projects
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/users`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(options)
    const resp = response.data
    return resp
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const getEmailVerificationCode = async () => {
  const user = getUser()
  const token = user.jwt

  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/users/email/verification`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(options)
    const resp = response.data
    console.log('resp', resp)
    return resp
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const validateEmailVerificationCode = async (code) => {
  const user = getUser()
  const token = user.jwt

  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/users/email/verification`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: { code }
    }
    const result = await axios(options)
    const response = result.data
    console.log('response', response)
    setUser({
      _id: response.user._id,
      email: response.user.email,
      jwt: token,
      username: response.user.username || '',
      emailVerified: response.user.emailVerified,
      chatId: response.user.chatId,
      credit: response.user.credit
    })

    return true
  } catch (e) {
    console.log(e)
    throw e
  }
}
export const loadFreeCredit = async () => {
  const user = getUser()
  const token = user.jwt

  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/users/credit/load`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        credit: { client: 'token-tiger' }
      }
    }
    const result = await axios(options)
    const response = result.data.result
    console.log('response', response)

    user.credit = response

    setUser(user)

    return true
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const fetchUserData = async () => {
  const user = getUser()
  const token = user.jwt

  // try to get all projects
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/users/${user._id}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(options)
    const resp = response.data
    return resp.user
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const contactUs = async (body) => {
  try {
    const user = getUser()
    const token = user.jwt
    const options = {
      method: 'POST',
      url: `${SERVER}/contact/us`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: body
    }
    const result = await axios(options)
    const response = result.data // await data.json()
    return response
  } catch (e) {
    console.warn('Error in auth/deletePayment()', e.message)
    throw e
  }
}
