import React, { useState, useEffect, useRef } from 'react'
import UppyHandler from '../components/uppy-handler'
import { Formik } from 'formik' // Formik documentation https://formik.org/docs/overview

// Services
import {
  getDataFromGateway,
  updateMutableData,
  getTokenData
} from '../services/token'

// Styles
import mutableDStyles from './styles/updateMutableData.module.scss'

// Icons
import xMark from '../components/assets/xmark.svg'
import tiger from '../components/assets/work-tiger.png'

// Notifications
import { toast } from 'react-toastify'

// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

// JSON format placeholder
const jsonExample = JSON.stringify(
  {
    key: 'key value string',
    key2: new Date().getTime(),
    key3: null
  },
  null,
  2
)

export default function UpdateMutableData (props) {
  const [form, setForm] = useState({ nsfw: false, about: '', userData: {} })
  const [loaded, setLoaded] = useState(false)
  const [inFetch, setInFetch] = useState(true)
  const [successData, setSuccessData] = useState(null)
  const [customUppySpinner, setCustomUppySpinner] = useState(false)

  const uppyRef = useRef()

  const { close } = props

  // clear uppy
  useEffect(() => {
    if (loaded && uppyRef && uppyRef.current) {
      uppyRef.current.clearUppy()
    }
  }, [loaded])

  useEffect(() => {
    // Get current token mutable data and pre load form with that data.
    const fetchCurrentData = async () => {
      try {
        setInFetch(true)
        /*
        Old tokens do not have the (mutableDataCid) property in the database
        const currentD = await getTokenInfo(props.token.tokenId)
        const { mutableDataCid } = currentD.result */
        const dataResult = await getTokenData([props.token.tokenId])
        const result = dataResult.result[0]
        if (!result || !result.mutableData) { throw new Error('mutable data not found!') }

        // Split CID from url.
        const mutableDataIPFSCid = result.mutableData
        const index = mutableDataIPFSCid.lastIndexOf('/')
        const mutableDataCid = mutableDataIPFSCid.substring(
          index + 1,
          mutableDataIPFSCid.length
        )

        const mutableData = await getDataFromGateway(mutableDataCid)
        if (!mutableData) {
          throw new Error('Mutable daba cannot be retrieved from the gateway')
        }
        const defaultForm = {
          nsfw: mutableData.nsfw,
          about: mutableData.about,
          userData: getUserData(mutableData.userData) // mutableData.userData,
        }

        setForm(defaultForm)
        setLoaded(true)
        setInFetch(false)
      } catch (error) {
        setInFetch(false)
        toast.error('Error! ' + error.message)
        props.close()
        throw error
      }
    }

    if (!loaded) {
      fetchCurrentData()
    }
  }, [loaded, props])

  // Manipulate the userData to display it on the form.
  const getUserData = (userData) => {
    let stringify
    let obj = userData

    // Get the object string in a user-readable way.
    if (typeof userData === 'string') {
      // This removes the "/" that often appear on different objects.
      obj = JSON.parse(userData, null, 2)
      stringify = JSON.stringify(JSON.parse(userData), null, 2)
    } else {
      stringify = JSON.stringify(userData, null, 2)
    }

    // Set as an empty string if the json does not have any key.
    // The purpose of this is to be able to show the placeholder to the user without changing
    // the value of the userData property.
    if (!Object.keys(obj).length) {
      stringify = ''
    }
    return stringify
  }

  const validateFormValues = (values) => {
    const errors = {}
    try {
      if (!values.about) {
        errors.about = 'about value is required.'
      }
      if (values.userData) {
        try {
          JSON.parse(values.userData)
        } catch (error) {
          errors.userData = 'userData value must be JSON format.'
        }
      }
      return errors
    } catch (error) {
      console.error(error)
    }
  }
  const submit = async (values) => {
    try {
      setInFetch(true)
      // get loaded files
      const hasLoadedFiles = uppyRef.current.hasLoadedFiles()

      const loadedFile = hasLoadedFiles[hasLoadedFiles.length - 1]
      // upload files the user load any file.
      let fileName
      if (loadedFile) {
        const uppyResult = await uppyRef.current.submitFiles()
        if (!uppyResult) {
          throw new Error('Error uploading files')
        }
        fileName = loadedFile.name
      }

      // update mutable data
      const customData = values
      const tokenId = props.token.tokenId
      if (!customData.userData) customData.userData = JSON.stringify({})
      await updateMutableData({ tokenId, fileName, customData })

      setInFetch(false)
      setSuccessData({})
      toast.success('Success!')
      if (props.success) props.success()
    } catch (error) {
      toast.error('Error! ' + error.message)
      setInFetch(false)
      reloadUppy()
    }
  }
  // Clean uppy and load the files again
  // this prevent some issues on uppy after error
  const reloadUppy = () => {
    try {
      const files = uppyRef.current.hasLoadedFiles()
      uppyRef.current.clearUppy()
      setTimeout(() => {
        uppyRef.current.addFile(files[0])
      }, 1000)
    } catch (error) {
      console.warn(error)
    }
  }

  // Event on uppy changes
  // Used to manipulate the appearance of the dashboard and hide/show files.
  const onUppyChanges = async (file) => {
    try {
      if (!file) {
        return
      }
      if (file.meta.isThumbnail) return

      // Hide uppy dashboard and show the spinner
      const uppyContentElement = window.document.getElementById(
        'uppy-custom-wrapper'
      )
      uppyContentElement.style.opacity = 0
      setCustomUppySpinner(true)

      // 1 second timeout to fix view.
      setTimeout(() => {
        // const files = uppyRef.current.hasLoadedFiles()
        uppyRef.current.removeFile(file)
        // hide spinner
        setCustomUppySpinner(false)
        // Show the uppy dashboard.
        uppyContentElement.style.opacity = 1
      }, 1000)
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <>
      {!successData && loaded && (
        <Formik
          initialValues={form}
          onSubmit={submit}
          validate={validateFormValues}
        >
          {(props) => {
            const { values, errors, handleChange, handleSubmit } = props
            return (
              <div className={mutableDStyles.container}>
                <img
                  className={mutableDStyles.closeBtn}
                  src={xMark}
                  alt='close icon'
                  onClick={close}
                />
                <div className={mutableDStyles.titleSection}>
                  <h2>Change NFT Mutable Data</h2>
                  <p>
                    Here you can customize each token's mutable data properties
                    to enhance it’s functionality. Modify fields such as the
                    token icon and more!
                  </p>
                </div>
                <hr />
                {inFetch && (
                  <PropagateLoader
                    color='#ffffff'
                    loading={inFetch}
                    size={5}
                    cssOverride={{
                      display: 'block',
                      textAlign: 'center',
                      marginBottom: '2.5em',
                      marginTop: '2.5em'
                    }}
                    speedMultiplier={1}
                  />
                )}
                {!inFetch && (
                  <div className={mutableDStyles.formSection}>
                    <div className={mutableDStyles.box}>
                      <h3>New Token Icon</h3>
                      <span>
                        Here you can upload a new image to set as the new token
                        icon.
                      </span>
                      <div className={mutableDStyles.uppyBorderPanel}>
                        <div
                          id='uppy-custom-wrapper'
                          className={mutableDStyles.uppyPanel}
                        >
                          <UppyHandler ref={uppyRef} onChange={onUppyChanges} />
                        </div>
                        <PropagateLoader
                          color='#ffffff'
                          loading={customUppySpinner}
                          size={5}
                          cssOverride={{
                            display: 'block',
                            textAlign: 'center',
                            marginBottom: '0',
                            top: '-50%',
                            width: '100%'
                          }}
                          speedMultiplier={1}
                        />
                      </div>
                      <h3>NSFW</h3>
                      <span>
                        Here you can change the current not safe for work
                        property value{' '}
                      </span>
                      <div className={mutableDStyles.toggleExplicitBtn}>
                        <input
                          type='checkbox'
                          id='nsfw'
                          name='nsfw'
                          checked={values.nsfw}
                          onChange={handleChange}
                        />
                        <label htmlFor='nsfw'>Toggle</label>
                      </div>
                    </div>
                    <div className={mutableDStyles.box}>
                      <h3>User Data</h3>
                      <span>
                        Here you can edit the user data property, it requires to
                        be in JSON format
                      </span>
                      <textarea
                        className={mutableDStyles.longInput}
                        name='userData'
                        id='userData'
                        cols='25'
                        rows='5'
                        value={values.userData}
                        placeholder={jsonExample}
                        onChange={handleChange}
                      />
                      {errors.userData && (
                        <div className={mutableDStyles.inputFeedback}>
                          {errors.userData}
                        </div>
                      )}

                      <h3>About</h3>
                      <span>
                        Here you can edit the about property to include new
                        description information about the NFT
                      </span>
                      <textarea
                        className={mutableDStyles.longInput}
                        name='about'
                        id='about'
                        cols='25'
                        rows='5'
                        value={values.about}
                        placeholder=''
                        onChange={handleChange}
                      />
                      {errors.about && (
                        <div className={mutableDStyles.inputFeedback}>
                          {errors.about}
                        </div>
                      )}
                    </div>

                    <div className={mutableDStyles.box}>
                      <button
                        className={mutableDStyles.submitBtn}
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )
          }}
        </Formik>
      )}
      {inFetch && !loaded && (
        <div className={mutableDStyles.container}>
          <PropagateLoader
            color='#ffffff'
            loading={inFetch}
            size={5}
            cssOverride={{
              display: 'block',
              textAlign: 'center',
              marginBottom: '2.5em',
              marginTop: '10.5em'
            }}
            speedMultiplier={1}
          />
        </div>
      )}
      {successData && (
        <div className={mutableDStyles.container}>
          <img
            className={mutableDStyles.closeBtn}
            src={xMark}
            alt='close icon'
            onClick={close}
          />
          <div className={mutableDStyles.updateMsgSection}>
            <img src={tiger} alt='tiger working' />
            <span>You NFT data should be updated soon.</span>
          </div>
        </div>
      )}
    </>
  )
}
