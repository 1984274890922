import React, { useState, useEffect, useCallback } from 'react'

import credits from './assets/credits.svg'
import verify from './assets/verify.svg'
import telegram from './assets/telegram.svg'
import bot from './assets/bot.svg'
import creditModalStyles from './styles/creditsModal.module.scss'
import {
  getEmailVerificationCode,
  validateEmailVerificationCode,
  loadFreeCredit,
  fetchUserData
} from '../services/user'
import { getUser } from '../services/auth'
import PropagateLoader from 'react-spinners/PropagateLoader'

// Notification lib
import { toast } from 'react-toastify'

// Redux Functions
import actions from '../redux/actions'
import { Link } from 'react-router-dom'
const { useSelector, useDispatch, updateCreditData } = actions

export default function CreditsModal (props) {
  const [inFetch, setInFetch] = useState(false)
  const [user, setUser] = useState(null)
  const [emailVerify, setEmailVerify] = useState(false)
  const [telegramVerify, setTelegramVerify] = useState(false)
  const [emailCode, setEmailCode] = useState('')
  const { close } = props

  const myCredits = useSelector((state) => state.credit.value)
  const dispatch = useDispatch()

  useEffect(() => {})

  const getUserData = useCallback(async () => {
    console.log('getting user data')
    const _user = await fetchUserData()
    const credits = _user.credit
    dispatch(updateCreditData(credits))

    if (credits.available > myCredits.available) {
      toast.info('New credits loaded!')
    }

    setUser(_user)
  }, [dispatch, myCredits.available])

  // Get credits and update redux store
  useEffect(() => {
    getUserData()
  }, [getUserData, dispatch])

  const handleEmailVerify = async () => {
    try {
      setInFetch(true)
      await getEmailVerificationCode()
      setEmailVerify(true)
      setInFetch(false)
    } catch (error) {
      toast.error('Error! : ' + error.message)
      console.warn(error)
      setInFetch(false)
    }
  }

  const handleEmailCode = (e) => {
    if (e.target.value.length > 6) {
      return
    }
    if (Number(e.target.value) || !e.target.value) {
      setEmailCode(e.target.value)
    }
  }

  const submitEmailCode = async () => {
    try {
      setInFetch(true)

      await validateEmailVerificationCode(emailCode)
      const _user = getUser()
      setUser(_user)
      setInFetch(false)
      setEmailVerify(false)
      toast.success('Success!')
    } catch (error) {
      console.warn(error)
      setInFetch(false)
      if (error && error.response && error.response.data) {
        toast.error('Error! : ' + error.response.data)
        return
      }
      toast.error('Error! : ' + error.message)
    }
  }

  const handleGetCredit = async () => {
    try {
      setInFetch(true)

      await loadFreeCredit()
      const _user = await fetchUserData()
      dispatch(updateCreditData(_user.credit))
      setInFetch(false)
      toast.success('Credit load was successful.')
      // close()
    } catch (error) {
      console.warn(error)
      setInFetch(false)
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.match('Verification is required')
      ) {
        toast.error(
          'Error: You need email or telegram verification before loading credits.',
          { autoClose: 4500 }
        )
        return
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.match('credit limit')
      ) {
        toast.error('Error: You exceeded the daily credit limit.', {
          autoClose: 4000
        })
        return
      }
      toast.error('Error: ' + error.message)
    }
  }

  // const handleJoinTelegram = () => {
  //   const link = process.env.REACT_APP_TELGREM_GROUP_LINK

  //   if (link) {
  //     window.open(link, '__blank')
  //   }
  // }

  return (
    <>
      {!inFetch && user && (
        <>
          {emailVerify && (
            <div className={creditModalStyles.container}>
              <h2>Email Verification</h2>
              <p>
                To verify your TokenTiger account, please enter the 6-digit code
                that we sent to your email address. If you don't see the email
                in your inbox, check your spam or junk folder.
              </p>
              <input
                type='text'
                placeholder='6-digit code'
                value={emailCode}
                onChange={handleEmailCode}
              />
              <div className={creditModalStyles.btnArea}>
                <button
                  className={creditModalStyles.closeBtn}
                  onClick={() => setEmailVerify(false)}
                >
                  Back
                </button>
                <button
                  className={creditModalStyles.submitBtn}
                  onClick={submitEmailCode}
                >
                  Submit
                </button>
              </div>
            </div>
          )}

          {telegramVerify && (
            <div className={creditModalStyles.container}>
              <h2>Telegram Verification</h2>
              <p>
                To verify your account, please join our Telegram group and use
                our Telegram bot. If you have any questions or issues, please
                check our FAQs page for detailed instructions on how to use the
                bot.
              </p>
              <div className={creditModalStyles.iconsSection}>
                <img
                  className={creditModalStyles.pulse}
                  src={telegram}
                  alt='telegram icon'
                />
                <img
                  className={creditModalStyles.bot}
                  src={bot}
                  alt='telegram icon'
                />
              </div>
              <div className={creditModalStyles.btnArea}>
                <a
                  href='https://t.me/+OEzx9kpde2llNTAx'
                  target='_blank'
                  without
                  rel='noreferrer'
                  className={creditModalStyles.joinBtn}
                >
                  Join TokenTiger Telegram Channel
                </a>
              </div>
              <button
                className={creditModalStyles.closeBtn}
                onClick={() => setTelegramVerify(false)}
              >
                Back
              </button>
            </div>
          )}

          {!emailVerify && !telegramVerify && (
            <div className={creditModalStyles.container}>
              <h2>TokenTiger Credits</h2>
              <p>
                To use TokenTiger, you need to have credits, which are the
                internal currency of the platform. Credits can be used to create
                NFTs, and to access other features on the website. You can
                recharge credits here.
              </p>
              <div className={creditModalStyles.bar} />
              <div className={creditModalStyles.balanceSection}>
                <img src={credits} alt='credits logo' />
                <h3>Credit Balance: {myCredits.available}</h3>
                <button onClick={handleGetCredit}>Free Credits</button>
                <Link className={creditModalStyles.link} to='/payment'>
                  <button onClick={close}>Buy Credits</button>
                </Link>
              </div>
              <div className={creditModalStyles.bar} />
              <div className={creditModalStyles.verificationSection}>
                <h3>Verification Status</h3>
                <span>
                  To get TokenTiger credits, you need to verify your account.
                  You can do this by verifying your email or your telegram
                  account. Once you verify your identity, you will be able to
                  claim your credits and be able to access all the features of
                  TokenTiger.
                </span>
                <div className={creditModalStyles.badgeSection}>
                  <div className={creditModalStyles.telegramSection}>
                    <img
                      className={
                        user && user.chatId
                          ? creditModalStyles.telegramBadgeVerified
                          : creditModalStyles.telegramBadge
                      }
                      src={verify}
                      alt='telegram verification badge'
                    />
                    <span>
                      Telegram: <br />{' '}
                      {user && user.chatId ? 'Verified' : ' Not verified'}
                    </span>

                    {user && !user.chatId && (
                      <button
                        className={creditModalStyles.verifyBtn}
                        onClick={() => setTelegramVerify(true)}
                      >
                        Verify
                      </button>
                    )}
                  </div>
                  <div className={creditModalStyles.emailSection}>
                    <img
                      className={
                        user && user.emailVerified
                          ? creditModalStyles.emailBadgeVerified
                          : creditModalStyles.emailBadge
                      }
                      src={verify}
                      alt='email verification badge'
                    />
                    <span>
                      Email: <br />
                      {user && user.emailVerified
                        ? 'Verified'
                        : ' Not verified'}
                    </span>
                    {user && !user.emailVerified && (
                      <button
                        className={creditModalStyles.verifyBtn}
                        onClick={handleEmailVerify}
                      >
                        Verify
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <button className={creditModalStyles.closeBtn} onClick={close}>
                Close
              </button>
            </div>
          )}
        </>
      )}

      {inFetch && (
        <div className={creditModalStyles.container}>
          <PropagateLoader
            color='rgb(59, 159, 111)'
            loading={inFetch}
            size={7}
            cssOverride={{
              display: 'block',
              textAlign: 'center'
            }}
            speedMultiplier={1}
            style={{ position: 'absolute', top: '60%', left: '50%' }}
          />
        </div>
      )}
    </>
  )
}
