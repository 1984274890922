import React, { useState, useRef } from 'react'
import contactStyles from './styles/contact.module.scss'
import { Formik } from 'formik' // Formik documentation https://formik.org/docs/overview
import telegramIcon from '../components/assets/telegram.svg'
import { getUser } from '../services/auth'
import * as EmailValidator from 'email-validator'

// Notifications
import { toast } from 'react-toastify'

// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'
import { contactUs } from '../services/user'

export default function Contact () {
  // Form data
  const defaultform = {
    name: '',
    lastName: '',
    email: getUser().email || '',
    message: '',
    subject: 'Contact Message' // Handled by app.
  }
  const [form, setForm] = useState(defaultform)
  const [inFetch, setInFetch] = useState(false)
  const formikRef = useRef()

  // submit data
  const submit = async (values) => {
    try {
      setInFetch(true)
      await contactUs(values)

      setInFetch(false)
      toast.success('Success!')
      clearform()
    } catch (error) {
      toast.error('Error! ' + error.message)
      setInFetch(false)
    }
  }

  // Validate form fields
  const validateFormValues = (values) => {
    const errors = {}
    try {
      if (!values.name) {
        errors.name = 'Name is required.'
      }
      if (!values.lastName) {
        errors.lastName = 'Last name is required.'
      }
      if (!EmailValidator.validate(values.email)) {
        errors.email = 'Invalid email address.'
      }
      if (!values.email) {
        errors.email = 'Email is required.'
      }

      if (!values.message) {
        errors.message = 'Message is required.'
      }

      return errors
    } catch (error) {
      console.error(error)
    }
  }
  // Set form to default.
  const clearform = () => {
    setForm(defaultform)
    const keys = Object.keys(defaultform)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      formikRef.current.setFieldValue(key, defaultform[key])
    }
  }
  return (
    <>
      <div className={contactStyles.container}>
        <div className={contactStyles.infoSection}>
          <h1>Contact Us</h1>
          <p>
            Looking for special pricing on creating tokens with us? We're here
            to help!
          </p>
          <p>
            Reach out to us by filling out the form, we'll be happy to discuss
            the details and offer you a customized quote.
          </p>

          <div className={contactStyles.infoSectionBottom}>
            <div>
              <h3>Customer Support</h3>
              <p>
                Our support team is available around the clock to address any
                concerns or querys you may have.
              </p>
            </div>

            <div>
              <h3>Feedback and Suggestions</h3>
              <p>
                We value your feedback and are continously working to improve
                TokenTiger.
              </p>
            </div>

            <div>
              <h3>Media Inquiries</h3>
              <p>
                Interested in featuring TokenTiger in your next article,
                podcast, or video? We'd be delighted to assist with your media
                inquiries.
              </p>
            </div>
          </div>
        </div>
        <Formik
          initialValues={form}
          onSubmit={submit}
          validate={validateFormValues}
          innerRef={formikRef}
        >

          {(props) => {
            const { values, errors, handleChange, handleSubmit, handleBlur, touched } = props
            return (
              <div className={contactStyles.formSection}>
                <h2>Get in Touch</h2>
                <p>You can reach us anytime</p>

                <div className={contactStyles.formInputTop}>
                  <div>
                    <input
                      id='name'
                      placeholder='First name' type='text'
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.name && touched.name && (
                      <div className={contactStyles.inputFeedback}>
                        {errors.name}
                      </div>
                    )}
                  </div>
                  <div>
                    <input
                      id='lastName'
                      placeholder='Last name'
                      type='text'
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}

                    />
                    {errors.lastName && touched.lastName && (
                      <div className={contactStyles.inputFeedback}>
                        {errors.lastName}
                      </div>
                    )}
                  </div>

                </div>

                <div className={contactStyles.emailInput}>
                  <input
                    id='email'
                    placeholder='Your email'
                    type='email'
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}

                  />
                  {errors.email && touched.email && (
                    <div className={contactStyles.inputFeedback}>
                      {errors.email}
                    </div>
                  )}
                </div>

                <textarea
                  className={contactStyles.longInput}
                  placeholder='How can we help?'
                  name=''
                  id='message'
                  cols='10'
                  rows='5'
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.message && touched.message && (
                  <div className={contactStyles.inputFeedback}>
                    {errors.message}
                  </div>
                )}
                {/** Submit btn */}
                {!inFetch && (
                  <button
                    className={contactStyles.submitBtn}
                    onClick={handleSubmit}
                    disabled={Object.keys(errors).length}
                  >Submit
                  </button>
                )}

                {/** Loader */}
                {inFetch && (
                  <div>
                    <PropagateLoader
                      color='rgb(59, 159, 111)'
                      loading={inFetch}
                      size={7}
                      cssOverride={{
                        display: 'block',
                        textAlign: 'center'
                      }}
                      speedMultiplier={1}
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        marginTop: '1em',
                        marginBottom: '2em'
                      }}
                    />
                  </div>
                )}

                <div className={contactStyles.telegramSection}>
                  <img src={telegramIcon} alt='telegram icon' />{' '}
                  <a
                    href='https://t.me/+OEzx9kpde2llNTAx'
                    target='_blank'
                    without
                    rel='noreferrer'
                  >
                    Join our Telegram Group
                  </a>
                </div>
              </div>
            )
          }}
        </Formik>
      </div>
    </>
  )
}
