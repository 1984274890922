import React, { useState, useEffect, useRef } from 'react'
import { Tooltip } from 'react-tippy'
import Popup from 'reactjs-popup'
import Export from './export'
import Burn from './burn'

// Icons
import paper from './assets/paper.svg'
import fire from './assets/fire.svg'
import question from './assets/question.svg'
import share from './assets/share-nodes.svg'
import edit from './assets/edit2.svg'
import tCardStyles from './styles/tokensCard.module.scss'
import tiger from '../components/assets/work-tiger.png'
import refresh from '../components/assets/refresh-svgrepo-com.svg'
// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

// Service
import { getTokenData, getTokenIcon, getTokenInfo } from '../services/token'

// Redux Functions
import actions from '../redux/actions'
import SharableModal from './sharableModal'
import UpdateMutableData from './updateMutableData'

const TOKEN_EXPLORER = process.env.REACT_APP_TOKEN_EXPLORER

const { useDispatch, updateTokenData } = actions

export default function TokensCard (props) {
  const { token, collection, updateSelectedTokens, selectedSection } = props
  const dispatch = useDispatch()

  const [reloading, setReloading] = useState(false)

  const [iconIsLoaded, setIconIsLoaded] = useState(false)

  const [dataIsLoaded, setDataIsLoaded] = useState(false)
  const [tokenIcon, setTokenIcon] = useState(null)

  const [inPinQueue, setInPinQueue] = useState(false)

  const ref = useRef()

  // on hide modal
  const closeTooltip = () => {
    ref.current.close()

    // refresh tokens view on close modal
    updateSelectedTokens()
  }

  // fetch the token-icon when the component mounts
  useEffect(() => {
    const fetchIcon = async () => {
      const tokenInfoRes = await getTokenInfo(token.tokenId)
      const tokenInfo = tokenInfoRes.result
      const { inPinQueue } = tokenInfo
      setInPinQueue(inPinQueue)

      const tokenId = token.tokenId
      const url = await getTokenIcon(tokenId)
      setTokenIcon(url)
      setIconIsLoaded(true)
    }

    if (!iconIsLoaded) {
      fetchIcon()
    }
  }, [iconIsLoaded, token])

  // fetch the token-data when the component mounts
  // this fetch its only for new tokens added recently
  // This functionality is called every time the <localStatus> value of the token changes.
  useEffect(() => {
    const fetchData = async () => {
      const dataResult = await getTokenData([token.tokenId])
      const data = dataResult.result
      const tokenResult = data[0].genesisData

      const collectionId = collection._id
        ? collection._id
        : collection.publicId
      // update token full data into redux store
      dispatch(updateTokenData({ collectionId, token: tokenResult }))
      setTimeout(() => {
        updateSelectedTokens()
      }, 500)
      setTimeout(() => {
        setDataIsLoaded(true)
      }, 1000)
    }

    // fetch token-data if the token has been added rencetly
    if (!dataIsLoaded && token.localStatus === 'NewToken') {
      fetchData()
    }
    // Spinner handlers
    if (token.localStatus === 'loading') {
      setDataIsLoaded(false)
    }
    if (!token.localStatus) {
      setDataIsLoaded(true)
    }
  }, [dataIsLoaded, token, collection, dispatch, updateSelectedTokens])

  const goToExplorer = () => {
    window.open(`${TOKEN_EXPLORER}/?tokenid=${token.tokenId}`, '_blank')
  }

  const reload = async () => {
    try {
      setReloading(true)
      const tokenInfoRes = await getTokenInfo(token.tokenId)
      const tokenInfo = tokenInfoRes.result
      const { inPinQueue } = tokenInfo
      setInPinQueue(inPinQueue)

      const tokenId = token.tokenId
      const url = await getTokenIcon(tokenId)
      setTokenIcon(url)
      setIconIsLoaded(true)
      setTimeout(() => {
        setReloading(false)
      }, 1000)
    } catch (error) {
      setReloading(false)
      console.warn(error)
    }
  }
  return (
    <div className={tCardStyles.container}>
      {iconIsLoaded && tokenIcon && !inPinQueue && (
        <div className={tCardStyles.file}>
          <img src={tokenIcon} alt='file icon' />
        </div>
      )}
      {iconIsLoaded && (inPinQueue || !tokenIcon) && (
        <div className={tCardStyles.file}>
          <img src={tiger} alt='file icon' />
        </div>
      )}
      {!reloading && dataIsLoaded && collection && (
        <>
          <div className={tCardStyles.textContent}>
            {collection && collection.projectLabel && (
              <h3>{collection.projectLabel}</h3>
            )}
            {collection && collection.collectionLabel && (
              <h3>{collection.collectionLabel}</h3>
            )}

            <h4>{token.name}</h4>
          </div>
          {!inPinQueue && (
            <div className={tCardStyles.icons}>
              <Popup
                ref={ref}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                trigger={<img src={paper} alt='export token' />}
                p
                modal
              >
                <span>
                  <Export
                    close={closeTooltip}
                    tokenIcon={tokenIcon}
                    collection={collection}
                    token={token}
                    selectedSection={selectedSection}
                  />
                </span>
              </Popup>
              <Popup
                ref={ref}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                trigger={<img src={fire} alt='burn token' />}
                p
                modal
              >
                <span>
                  <Burn
                    close={closeTooltip}
                    collection={collection}
                    token={token}
                    selectedSection={selectedSection}
                  />
                </span>
              </Popup>
              <Popup
                ref={ref}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                trigger={<img src={share} alt='share token' />}
                p
                modal
                disabled={!collection._id} // disabled for sharables
              >
                <span>
                  <SharableModal
                    collection={collection}
                    token={token}
                    close={closeTooltip}
                  />
                </span>
              </Popup>
              <Popup
                ref={ref}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                trigger={<img src={edit} alt='edit token' />}
                p
                modal
                disabled={!collection._id} // disabled for sharables
              >
                <span>
                  <UpdateMutableData
                    token={token}
                    close={closeTooltip}
                    success={reload}
                  />
                </span>
              </Popup>
              <Tooltip
                title='Some delay may be expected between creating a NFT and all NFT data being displayed'
                position='top-start'
                trigger='mouseenter'
                size='small'
                inertia='true'
                theme='dark'
              >
                <img src={question} alt='token info' onClick={goToExplorer} />
              </Tooltip>
            </div>
          )}
          {inPinQueue && !reloading && (
            <>
              <h3 className={tCardStyles.updatingText}>Updating...</h3>
              <div className={tCardStyles.iconsAround}>
                <Tooltip
                  title='Refresh Token data.'
                  position='top-start'
                  trigger='mouseenter'
                  size='small'
                  inertia='true'
                  theme='dark'
                >
                  <img
                    src={refresh}
                    style={{ margin: '0 auto' }}
                    alt='reload'
                    onClick={reload}
                  />
                </Tooltip>

                <Tooltip
                  title='Some delay may be expected between creating a NFT and all NFT data being displayed'
                  position='top-start'
                  trigger='mouseenter'
                  size='small'
                  inertia='true'
                  theme='dark'
                >
                  <img src={question} alt='token info' onClick={goToExplorer} />
                </Tooltip>
              </div>
            </>
          )}
        </>
      )}

      {(!iconIsLoaded || !dataIsLoaded || reloading) && (
        <PropagateLoader
          color='#ffffff'
          loading={!iconIsLoaded || !dataIsLoaded || reloading}
          size={5}
          cssOverride={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '2.5em',
            marginTop: '2.5em'
          }}
          speedMultiplier={1}
        />
      )}
    </div>
  )
}
