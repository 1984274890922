import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import paymentStyles from './styles/payment.module.scss'
import BuyCreditsModal from '../components/buyCreditsModal'
import Popup from 'reactjs-popup'
import { fetchUserData } from '../services/user'

// Notification lib
import { toast } from 'react-toastify'

// Redux Functions
import actions from '../redux/actions'
const { useSelector, useDispatch, updateCreditData } = actions

export default function Payment () {
  const [type, setType] = useState(false)
  const ref = useRef()

  const myCredits = useSelector((state) => state.credit.value)
  const dispatch = useDispatch()

  useEffect(() => {})

  const getUserData = useCallback(async () => {
    console.log('getting user data')
    const _user = await fetchUserData()
    const credits = _user.credit
    dispatch(updateCreditData(credits))

    if (credits.available > myCredits.available) {
      toast.info('New credits loaded!')
    }
  }, [dispatch, myCredits.available])

  const buy = (type) => {
    setType(type)
    ref.current.open()
  }

  const contactUs = () => {}

  const closeTooltip = useCallback(() => {
    ref.current.close()
  }, [ref])
  return (
    <>
      <div className={paymentStyles.container}>
        <h1>Buy TokenTiger Credits</h1>
        <p>Fuel your TokenTiger experience with credits!</p>

        <div className={paymentStyles.planSection}>
          <div className={paymentStyles.popular}>
            <span>Popular</span>
            <h3>Basic</h3>
            <p>Buy 3 TokenTiger Credits</p>
            <p className={paymentStyles.price}>$2.99</p>
            <button className={paymentStyles.buyBtn} onClick={() => buy(1)}>
              Buy Credits
            </button>
          </div>

          <div className={paymentStyles.best}>
            <span>Best</span>
            <h3>Advanced</h3>
            <p>Buy 10 TokenTiger Credits</p>
            <p className={paymentStyles.price}>$7.99</p>
            <span className={paymentStyles.regularPrice}>$9.99</span>
            <span className={paymentStyles.discount}>Save 20%</span>
            <button className={paymentStyles.buyBtn} onClick={() => buy(2)}>
              Buy Credits
            </button>
          </div>

          <div className={paymentStyles.enterprise}>
            <span className={paymentStyles.newTag}>New</span>
            <h3>Enterprise</h3>
            <p>For large-scale organizations</p>
            <Link className={paymentStyles.link} to='/contact'>
              <button className={paymentStyles.buyBtn} onClick={contactUs}>
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      <Popup
        ref={ref}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        trigger={<button className={paymentStyles.btn}>Buy Credits</button>}
        p
        modal
      >
        <span>
          <BuyCreditsModal
            close={closeTooltip}
            onSuccess={getUserData}
            type={type}
          />
        </span>
      </Popup>
    </>
  )
}
