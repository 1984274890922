import axios from 'axios'
const sessionKey = 'TokenTigerUser-payment'
const appModeKey = `${sessionKey}-mode`
const SERVER = process.env.REACT_APP_PAYMENT_URL

// Detect if the app is running in a browser.
export const isBrowser = () => typeof window !== 'undefined'

// Get user data from localstorage
export const getPaymentUser = () =>
  isBrowser() && window.localStorage.getItem(sessionKey)
    ? JSON.parse(window.localStorage.getItem(sessionKey))
    : {}

export const getAppMode = () =>
  isBrowser() && window.localStorage.getItem(appModeKey)
    ? window.localStorage.getItem(appModeKey)
    : ''
export const setAppMode = mode =>
  window.localStorage.setItem(appModeKey, mode)

// Save user data to localstorage
export const setPaymentUser = user =>
  window.localStorage.setItem(sessionKey, JSON.stringify(user))

export const getPaymentAddress = async ({ id }) => {
  // try auth
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/users/address/${id}`,
      headers: {
        Accept: 'application/json'
      }
    }
    const result = await axios(options)
    const response = result.data // await data.json()
    return response
  } catch (e) {
    console.warn('Error in auth/handleLogin()', e.message)
    throw e
  }
}

export const handlePaymentLogin = async ({ email, password }) => {
  // try auth
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/auth`,
      headers: {
        Accept: 'application/json'
      },
      data: {
        email,
        password
      }
    }
    const result = await axios(options)
    const response = result.data // await data.json()
    console.log('response', response)
    setPaymentUser({
      _id: response.user._id,
      jwt: response.token
    })
    return true
  } catch (e) {
    console.warn('Error in auth/handleLogin()', e.message)
    throw e
  }
}

export const createPayment = async ({ userId, type }) => {
  // try auth
  try {
    const token = getPaymentUser().jwt ? getPaymentUser().jwt : ''

    const options = {
      method: 'POST',
      url: `${SERVER}/payments`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        payment: {
          userId,
          type
        }
      }
    }
    const result = await axios(options)
    const response = result.data

    return response
  } catch (e) {
    console.warn('Error in auth/handleLogin()', e.message)
    throw e
  }
}

export const getPayments = async () => {
  try {
    const token = getPaymentUser().jwt ? getPaymentUser().jwt : ''

    const options = {
      method: 'GET',
      url: `${SERVER}/payments/`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const result = await axios(options)
    const response = result.data // await data.json()
    return response
  } catch (e) {
    console.warn('Error in auth/handleLogin()', e.message)
    throw e
  }
}
export const cancelPayment = async (id) => {
  try {
    const token = getPaymentUser().jwt ? getPaymentUser().jwt : ''

    const options = {
      method: 'PUT',
      url: `${SERVER}/payments/cancel/${id}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const result = await axios(options)
    const response = result.data // await data.json()
    return response
  } catch (e) {
    console.warn('Error in auth/cancelPayment()', e.message)
    throw e
  }
}

export const deletePayment = async (id) => {
  try {
    const token = getPaymentUser().jwt ? getPaymentUser().jwt : ''

    const options = {
      method: 'DELETE',
      url: `${SERVER}/payments/${id}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const result = await axios(options)
    const response = result.data // await data.json()
    return response
  } catch (e) {
    console.warn('Error in auth/deletePayment()', e.message)
    throw e
  }
}
